import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules,
      labelCol: {
        style: "min-width: 100px"
      },
      form: _vm.form
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "类目"
    }
  }, [_c("a-select", {
    attrs: {
      "show-search": "",
      size: "large",
      "filter-option": _vm.filterOption,
      placeholder: "请选择一级类目"
    },
    on: {
      change: _vm.handleFirstChange
    },
    model: {
      value: _vm.ruleForm.parentcategoryid,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "parentcategoryid", $$v);
      },
      expression: "ruleForm.parentcategoryid"
    }
  }, _vm._l(_vm.seriesCateList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name || "") + " ")]);
  }), 1), _c("a-select", {
    attrs: {
      "show-search": "",
      disabled: !_vm.secondSeriesCateList.length,
      "filter-option": _vm.filterOption,
      size: "large",
      placeholder: "请选择二级类目"
    },
    model: {
      value: _vm.ruleForm.categoryid,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "categoryid", $$v);
      },
      expression: "ruleForm.categoryid"
    }
  }, _vm._l(_vm.secondSeriesCateList, function (item, index) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name || "") + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "品牌"
    }
  }, [_c("a-select", {
    attrs: {
      size: "large",
      placeholder: "请选择品牌",
      "show-search": "",
      "filter-option": _vm.filterOption
    },
    model: {
      value: _vm.ruleForm.brandid,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "brandid", $$v);
      },
      expression: "ruleForm.brandid"
    }
  }, _vm._l(_vm.brandList, function (item, index) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "系列"
    }
  }, [_c("a-button", {
    attrs: {
      size: "large",
      type: "primary",
      disabled: !!_vm.editId
    },
    on: {
      click: function click($event) {
        return _vm.handleSeriesAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.editId ? "编辑系列" : "添加系列"))]), _vm._l(_vm.seriesList, function (item, index) {
    return _c("div", [_c("div", [_c("a-input", {
      staticClass: "s_title",
      attrs: {
        placeholder: "输入系列名称"
      },
      model: {
        value: item.name,
        callback: function callback($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    }), index != 0 && index == _vm.seriesList.length - 1 ? _c("span", {
      staticClass: "item_del",
      on: {
        click: function click($event) {
          return _vm.handleDeleteSeries(item, index);
        }
      }
    }, [_vm._v("删除")]) : _vm._e(), _c("span", {
      staticClass: "up_img"
    }, [_c("UploadImg", {
      attrs: {
        listType: "picture-card",
        max: 1,
        memorySize: "500kb",
        imgProportion: _vm.imgProportion
      },
      on: {
        previewImg: function previewImg($event) {
          return _vm.previewImg(item, index);
        }
      },
      model: {
        value: item.imgurl,
        callback: function callback($$v) {
          _vm.$set(item, "imgurl", $$v);
        },
        expression: "item.imgurl"
      }
    })], 1)], 1)]);
  })], 2)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["series:add:submit"],
      expression: "['series:add:submit']"
    }],
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };